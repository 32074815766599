export type ModelDateInfoItem = string | { prop: string, model: string };
export let ModelDatesDictionary: { [key: string]: ModelDateInfoItem[] } = {
        "BaseModel": [],
        "Common.Company.CompanyModel": [],
        "Common.CustomProperties.CustomPropertyModel": [],
        "Common.CustomProperties.PropertyIdModel": [],
        "Common.Security.InstallationKeyModel": ["ExpirationDate", "CreationDate"],
        "Common.Security.MaestroWebAuthenticityRequestModel": [],
        "Common.Security.MaestroWebAuthenticityResponseModel": [],
        "Common.Security.UserOptionsAccessModel": [],
        "Common.Settings.ClientInfoModel": [],
        "Common.Settings.MaestroBiSettingsModel": [],
        "Common.Settings.MobileUserSettingsModel": [{ prop: "DispatchTemplate", model: "Company.Template.DispatchTemplateModel" }, { prop: "Company", model: "Common.Company.CompanyModel" }],
        "Common.Settings.RegionalSettingsModel": [],
        "Common.Settings.UserSecurityLookupModel": [],
        "Common.Settings.UserSettingsModel": [],
        "Company.AnnexModel": [],
        "Company.CertificationModel": [],
        "Company.CurrencyModel": [],
        "Company.DbConnectionModel": [],
        "Company.DefinedTextModel": [],
        "Company.DeliveryAddressModel": [],
        "Company.DispatchEmployeeCertificationModel": ["ExpirationDate"],
        "Company.EmployeeCertificationModel": ["ExpirationDate"],
        "Company.EmployeeHoursSummaryHeaderModel": ["Date"],
        "Company.EmployeeHoursSummaryItemModel": ["Date", { prop: "Location", model: "Company.LocationModel" }],
        "Company.EquipmentCounterModel": [],
        "Company.EquipmentCounterSaveModel": [],
        "Company.EquipmentTypeModel": [],
        "Company.EquipmentTypeSearchModel": [],
        "Company.EvaluationCriteriaModel": [],
        "Company.EvaluationModel": [],
        "Company.EvaluationValueModel": [],
        "Company.InventoryProjectTransferDetailsModel": ["AccountingDate", "TimeStampTransaction"],
        "Company.InventoryProjectTransferItemModel": ["TimeStampTransfer"],
        "Company.InventoryProjectTransferItemResponseModel": [{ prop: "Item", model: "Company.InventoryProjectTransferItemModel" }],
        "Company.InventoryProjectTransferModel": ["AccountingDate"],
        "Company.InventoryProjectTransferUpdateFromCatalogInventoryModel": [{ prop: "Item", model: "Company.InventoryProjectTransferItemModel" }, { prop: "CatalogInventoryItem", model: "Company.Catalog.CatalogInventoryModel" }],
        "Company.InventorySiteTransferDetailsModel": ["AccountingDate"],
        "Company.InventorySiteTransferItemModel": [],
        "Company.InventorySiteTransferItemResponseModel": [{ prop: "Item", model: "Company.InventorySiteTransferItemModel" }],
        "Company.InventorySiteTransferModel": ["AccountingDate"],
        "Company.InventorySiteTransferUpdateFromCatalogInventoryModel": [{ prop: "Item", model: "Company.InventorySiteTransferItemModel" }, { prop: "CatalogInventoryItem", model: "Company.Catalog.CatalogInventoryModel" }],
        "Company.LocationModel": [],
        "Company.LocationTypeModel": [],
        "Company.NewMaterialReservationUpdateFromCatalogInventoryModel": [{ prop: "Item", model: "Company.InventorySiteTransferItemModel" }, { prop: "CatalogInventoryItem", model: "Company.Catalog.CatalogInventoryModel" }, { prop: "InventorySiteTransfer", model: "Company.InventorySiteTransferDetailsModel" }],
        "Company.PayPeriodModel": ["StartDate", "EndDate"],
        "Company.PrefixModel": [],
        "Company.ProjectSubContractorCertificationModel": ["ExpirationDate"],
        "Company.PurchaseOrderItemModel": ["OrderDate"],
        "Company.PurchaseOrderModel": ["OrderDate", { prop: "DeliveryAddress", model: "Company.LocationModel" }],
        "Company.ReceiptOfGoodsItemModel": [],
        "Company.ReceiptOfGoodsModel": ["AccountingDate", "ReceiptDate"],
        "Company.ReceiptOfGoodsResponseModel": [{ prop: "ReceiptOfGoods", model: "Company.ReceiptOfGoodsModel" }],
        "Company.ReceiptOfGoodsSerialNoModel": [],
        "Company.RegionModel": [],
        "Company.SalesPersonModel": [],
        "Company.SectorModel": [],
        "Company.ServiceCallLookupModel": [],
        "Company.ShopDrawingLightModel": [],
        "Company.ShopDrawingModel": ["RequiredDate", "SubmittedDate", "ReSubmittedDate", "ReleaseDate", "DeliveryDate"],
        "Company.SiteLocationModel": [],
        "Company.SiteModel": [],
        "Company.SkillModel": [],
        "Company.SupplierModel": [],
        "Company.TimeKeepingModel": ["PunchDate"],
        "Company.TimeKeepingResponseModel": ["PunchDate"],
        "Company.TradeModel": [],
        "Company.UserGridStateModel": [],
        "Company.UserGridStateSaveModel": [],
        "Company.WorkCategoryModel": [],
        "Company.WorkflowApprobationItemModel": [],
        "Company.WorkOrderLocationModel": [],
        "Company.WorkTaskModel": [],
        "Company.YearsLevelModel": [],
        "Company.Assignment.AssignmentModel": ["StartDateTime", "EndDateTime", "DailyEntryDate"],
        "Company.Bonus.BonusConfigModel": [],
        "Company.Bonus.BonusModel": [],
        "Company.Bonus.DefaultBonusParametersModel": [],
        "Company.Bonus.DefaultBonusResultModel": [],
        "Company.Catalog.CatalogInventoryModel": [],
        "Company.Catalog.CatalogInventoryReservationResponseModel": [],
        "Company.Catalog.CatalogItemModel": [],
        "Company.Catalog.CatalogItemPriceModel": ["TransactionDate"],
        "Company.Catalog.CatalogItemServiceQuotationInfosModel": [],
        "Company.Client.ClientLookupModel": [],
        "Company.Client.ClientModel": [],
        "Company.Contact.ContactLookupModel": [],
        "Company.Contact.ContactModel": [],
        "Company.Contact.SmsContactModel": [],
        "Company.Contract.ContractModel": [],
        "Company.CustomPrefixGroup.CustomPrefixGroupModel": [],
        "Company.Dispatch.DispatchEnRouteCheckModel": ["OtherDispatchTransportStartTime"],
        "Company.Dispatch.DispatchHeaderModel": [],
        "Company.Dispatch.DispatchLocalizationModel": [],
        "Company.Dispatch.DispatchRefuseModel": [{ prop: "LocalizationModel", model: "Company.Dispatch.LocalizationModel" }],
        "Company.Dispatch.DispatchStatusModel": ["Date", { prop: "localizationModel", model: "Company.Dispatch.LocalizationModel" }, { prop: "Signature", model: "Company.Document.DocumentModel" }],
        "Company.Dispatch.LocalizationModel": [],
        "Company.Dispatch.ProjectDispatchCompleteAllModel": [],
        "Company.Document.DocumentCategoryModel": [],
        "Company.Document.DocumentInfoModel": [],
        "Company.Document.DocumentModel": [],
        "Company.Document.DocumentSectionModel": [],
        "Company.Document.GroupedListDocumentModel": [],
        "Company.Document.ListDocumentModel": ["DateCreated"],
        "Company.Employee.DispatchEmployeeModel": [],
        "Company.Employee.DispatchEmployeeSkillModel": [{ prop: "Employee", model: "Company.Employee.EmployeeModel" }, { prop: "Skill", model: "Company.SkillModel" }],
        "Company.Employee.EmployeeModel": [],
        "Company.Employee.EmployeeModelBase": [],
        "Company.Employee.EmployeeSkillModel": [{ prop: "Employee", model: "Company.Employee.EmployeeModel" }, { prop: "Skill", model: "Company.SkillModel" }],
        "Company.Employee.EmployeeUnavailabilityModel": ["StartDate", "EndDate"],
        "Company.Employee.EmployeeUnavailabilityTypeModel": [],
        "Company.Employee.EmployeeWithEquipmentModel": [],
        "Company.Employee.TimeEntryEmployeeModel": [],
        "Company.Equipment.EquipmentModel": [],
        "Company.Estimate.EstimateModel": [],
        "Company.Estimate.ImportationMappingItemModel": [],
        "Company.Estimate.ImportationMappingModel": [],
        "Company.Estimate.TakeoffConfigModel": [],
        "Company.Estimate.TakeoffVariableModel": [],
        "Company.Inspection.InspectionHeaderModel": ["InspectionDate"],
        "Company.Inspection.InspectionItemModel": [{ prop: "Type", model: "Company.Inspection.InspectionTypeItemModel" }],
        "Company.Inspection.InspectionItemValueModel": [],
        "Company.Inspection.InspectionMeasurementTypeModel": [],
        "Company.Inspection.InspectionMeasurementTypeValueModel": [],
        "Company.Inspection.InspectionModel": ["InspectionDate"],
        "Company.Inspection.InspectionTypeItemModel": [{ prop: "MeasurementType", model: "Company.Inspection.InspectionMeasurementTypeModel" }],
        "Company.Inspection.InspectionTypeLookupModel": [],
        "Company.Inspection.InspectionTypeModel": [],
        "Company.MaestroBi.MaestroBiReportBlocDisplayModel": [],
        "Company.MaestroBi.MaestroBiReportHierarchyModel": [],
        "Company.MaestroBi.MaestroBiReportImportChoiceModel": [],
        "Company.MaestroBi.MaestroBiReportZipFileModel": [],
        "Company.MaestroBi.MaestroBiSessionModel": [],
        "Company.MaterialRequisition.MaterialRequisitionItemModel": ["RequiredDate"],
        "Company.MaterialRequisition.MaterialRequisitionItemSummaryModel": ["RequiredDate"],
        "Company.MaterialRequisition.MaterialRequisitionModel": ["DateEntered", { prop: "DeliveryAddress", model: "Company.LocationModel" }, "RequiredDate"],
        "Company.MaterialRequisition.MaterialRequisitionProjectResourceModel": ["ResourceDate", "RequiredDate"],
        "Company.MaterialRequisition.MaterialRequisitionSummaryModel": ["RequiredDate"],
        "Company.Procore.ProcoreApiClient": [],
        "Company.Procore.ProcoreCompanyConfigurationModel": [{ prop: "Production", model: "Company.Procore.ProcoreApiClient" }, { prop: "Sandbox", model: "Company.Procore.ProcoreApiClient" }],
        "Company.Procore.ProcoreConnectionInfoModel": [{ prop: "Production", model: "Company.Procore.ProcoreApiClient" }, { prop: "Sandbox", model: "Company.Procore.ProcoreApiClient" }],
        "Company.Procore.ProcoreConnectToProcoreResult": [{ prop: "Config", model: "Company.Procore.ProcoreCompanyConfigurationModel" }],
        "Company.Procore.ProcoreEntityToSynchronizeModel": [],
        "Company.Project.DispatchProjectHeaderModel": [],
        "Company.Project.DispatchProjectItemsCountModel": [],
        "Company.Project.DispatchProjectWorkScheduleModel": [],
        "Company.Project.InvoicingProjectModel": [],
        "Company.Project.ProjectActivityLookupModel": [],
        "Company.Project.ProjectActivityModel": [],
        "Company.Project.ProjectActivityProgressModel": [],
        "Company.Project.ProjectBaseModel": [],
        "Company.Project.ProjectConfigModel": [],
        "Company.Project.ProjectDispatchDateItemsCountModel": [],
        "Company.Project.ProjectGroupModel": [],
        "Company.Project.ProjectListModel": [],
        "Company.Project.ProjectLookupModel": [],
        "Company.Project.ProjectModel": ["StartDate", "DeliveryDate", { prop: "ProjectLocation", model: "Company.LocationModel" }],
        "Company.Project.Dispatch.BidItemProductionCollectionModel": [],
        "Company.Project.Dispatch.BidItemProductionModel": [],
        "Company.Project.Dispatch.DailyEntryCancelDispatchRequestModel": ["DispatchDate"],
        "Company.Project.Dispatch.DailyEntryEquipmentCounterModel": [],
        "Company.Project.Dispatch.DailyEntryEquipmentCounterSaveModel": [],
        "Company.Project.Dispatch.DailyEntryInterruptionModel": ["StartDateTime", "EndDateTime"],
        "Company.Project.Dispatch.DailyEntryInterruptionSaveModel": [],
        "Company.Project.Dispatch.DailyEntrySummaryModel": [{ prop: "Description", model: "Company.Project.Dispatch.ProjectDailyEntryWorkDescriptionModel" }],
        "Company.Project.Dispatch.DailyEntryTaskModel": [],
        "Company.Project.Dispatch.DailyEntryTaskSaveModel": [],
        "Company.Project.Dispatch.DispatchProjectAttendanceAddModel": [],
        "Company.Project.Dispatch.DispatchProjectAttendanceModel": ["DispatchStartTime", "DispatchEndTime", "FirstEntryStartTime", "FirstEntryEndTime", "LastEntryStartTime", "LastEntryEndTime"],
        "Company.Project.Dispatch.DispatchProjectBonusModel": [],
        "Company.Project.Dispatch.DispatchProjectDefaultBonusHeaderModel": [],
        "Company.Project.Dispatch.DispatchProjectDefaultBonusModel": [],
        "Company.Project.Dispatch.DispatchProjectDefaultTimesModel": ["StartTime", "EndTime"],
        "Company.Project.Dispatch.DispatchProjectResourceModel": [],
        "Company.Project.Dispatch.DispatchProjectTimeEntriesSummaryEntryModel": ["StartDateTime", "EndDateTime"],
        "Company.Project.Dispatch.DispatchProjectTimeEntriesSummaryProjectModel": ["DispatchDate", "FirstTimeEntryStartTime", "LastTimeEntryEndTime"],
        "Company.Project.Dispatch.DispatchProjectTimeEntriesSummaryResourceModel": [],
        "Company.Project.Dispatch.DispatchProjectTimeEntryConflictModel": ["StartDateTime", "EndDateTime"],
        "Company.Project.Dispatch.DispatchProjectTimeEntryModel": ["StartDateTime", "EndDateTime"],
        "Company.Project.Dispatch.DispatchProjectTimeEntrySaveModel": ["StartDateTime", "EndDateTime"],
        "Company.Project.Dispatch.DispatchProjectTimeEntrySaveResultEntry": [],
        "Company.Project.Dispatch.DispatchProjectTimeEntrySaveResultModel": [],
        "Company.Project.Dispatch.DispatchProjectUpdateAttendanceModel": [],
        "Company.Project.Dispatch.EmployeeTeamModel": [],
        "Company.Project.Dispatch.PlaceModel": [],
        "Company.Project.Dispatch.ProductionCollectionModel": [],
        "Company.Project.Dispatch.ProductionModel": [],
        "Company.Project.Dispatch.ProjectAttendanceModel": ["StartTime", "EndTime"],
        "Company.Project.Dispatch.ProjectDailyEntryReadyToCompleteModel": [],
        "Company.Project.Dispatch.ProjectDailyEntryWeatherModel": [],
        "Company.Project.Dispatch.ProjectDailyEntryWorkDescriptionModel": [],
        "Company.Project.Dispatch.ProjectDispatchBreaksModel": ["StartTime", "EndTime"],
        "Company.Project.Dispatch.ProjectDispatchBreaksScheduleModel": [],
        "Company.Project.Dispatch.ProjectDispatchCompleteModel": [],
        "Company.Project.Dispatch.ProjectDispatchDateDetailsModel": [{ prop: "Weather", model: "Company.Project.Dispatch.ProjectDailyEntryWeatherModel" }, { prop: "Description", model: "Company.Project.Dispatch.ProjectDailyEntryWorkDescriptionModel" }, { prop: "ReadyToComplete", model: "Company.Project.Dispatch.ProjectDailyEntryReadyToCompleteModel" }, { prop: "Contract", model: "Company.Contract.ContractModel" }, { prop: "EntryStatus", model: "Company.Project.Dispatch.ProjectDispatchStatusModel" }, "Date"],
        "Company.Project.Dispatch.ProjectDispatchDateModel": ["Date"],
        "Company.Project.Dispatch.ProjectDispatchEmpBonusModel": [],
        "Company.Project.Dispatch.ProjectDispatchModel": ["StartTime", "EndTime"],
        "Company.Project.Dispatch.ProjectDispatchStatusModel": [],
        "Company.Project.Dispatch.ProjectDispatchSubcontractorCollectionModel": [],
        "Company.Project.Dispatch.ProjectDispatchSubcontractorContactModel": ["StartTime", "EndTime"],
        "Company.Project.Dispatch.ProjectDispatchSubcontractorModel": ["StartTime", "EndTime"],
        "Company.Project.Dispatch.ProjectRequisitionModel": ["RequestDate"],
        "Company.Project.Material.DailyEntryMaterialBaseModel": [],
        "Company.Project.Material.DailyEntryMaterialModel": [],
        "Company.Project.Material.DailyEntryMaterialSaveModel": [],
        "Company.Recommendation.RecommendationModel": ["DateEntered", "FollowUpDate", "NextFollowUpDate"],
        "Company.Security.MaestroBiDatasetSecuritySettingsModel": [],
        "Company.Security.MaestroBiEntitySecurityModel": [],
        "Company.Security.MaestroBiEntitySecuritySettingsModel": [],
        "Company.Security.MaestroBiSecuritySettingsModel": [],
        "Company.Security.ServiceQuotationSecuritySettingsModel": [],
        "Company.Service.CallHistoryModel": ["AssignedDate", "AssignedEndDate"],
        "Company.Service.CallModel": [{ prop: "Contract", model: "Company.Service.ContractModel" }, { prop: "ContactDetails", model: "Company.Contact.ContactModel" }, { prop: "PreviousNote", model: "Company.Service.ServiceCallNoteModel" }, { prop: "ReadyToComplete", model: "Company.Service.DispatchReadyToCompleteModel" }, "AssignedDate", "AssignedEndDate", "TravelStartTime", { prop: "Location", model: "Company.LocationModel" }, "JobDate", "LastVisitDate"],
        "Company.Service.CallReturnEquipmentMaintenanceTaskModel": [],
        "Company.Service.CallReturnEquipmentModel": [],
        "Company.Service.CallSummaryModel": [{ prop: "Detail", model: "Company.Service.CallModel" }, { prop: "Labors", model: "Company.Service.ServiceCallTimesheetHeaderModel" }, { prop: "Client", model: "Company.Client.ClientModel" }, { prop: "Status", model: "Company.Service.ServiceCallStatusModel" }],
        "Company.Service.ContractDetailModel": [{ prop: "Location", model: "Company.LocationModel" }, "StartDate", "EndDate"],
        "Company.Service.ContractDetailSearchModel": [{ prop: "Location", model: "Company.LocationModel" }, "StartDate", "EndDate"],
        "Company.Service.ContractModel": ["StartDate", "EndDate"],
        "Company.Service.DispatchReadyToCompleteModel": [],
        "Company.Service.RecommendationTypeModel": [],
        "Company.Service.SearchCallHistoryModel": [],
        "Company.Service.ServiceCallActivityModel": [],
        "Company.Service.ServiceCallContractEquipmentModel": ["WarrantyStartDate", "MaterialWarrantyEndDate", "LabourWarrantyEndDate", "DateEffective", "InactivityStartDate", "OriginalInstallDate"],
        "Company.Service.ServiceCallContractEquipmentReplaceModel": [{ prop: "NewActiveEquipment", model: "Company.Service.ServiceCallContractEquipmentModel" }, { prop: "InactiveEquipment", model: "Company.Service.ServiceCallContractEquipmentModel" }],
        "Company.Service.ServiceCallContractEquipmentStatusModel": ["WarrantyStartDate", "MaterialWarrantyEndDate", "LabourWarrantyEndDate", "DateEffective", "InactivityStartDate", "OriginalInstallDate"],
        "Company.Service.ServiceCallCustomStatusModel": [],
        "Company.Service.ServiceCallDispatchCreationModel": [{ prop: "Location", model: "Company.LocationModel" }, "DispatchDate", "DispatchEndDate", { prop: "LocalizationModel", model: "Company.Dispatch.LocalizationModel" }],
        "Company.Service.ServiceCallDispatchListModel": ["AssignedDate", "AssignedEndDate", "TravelStartTime", { prop: "Location", model: "Company.LocationModel" }, "JobDate", "LastVisitDate"],
        "Company.Service.ServiceCallDispatchModel": [{ prop: "ReadyToComplete", model: "Company.Service.DispatchReadyToCompleteModel" }, "AssignedDate", "AssignedEndDate", "TravelStartTime", { prop: "Location", model: "Company.LocationModel" }, "JobDate", "LastVisitDate"],
        "Company.Service.ServiceCallEquipmentBrandModel": [],
        "Company.Service.ServiceCallEquipmentCategoryModel": [],
        "Company.Service.ServiceCallEquipmentDetailModel": [],
        "Company.Service.ServiceCallEquipmentMaintenanceMaterialModel": [],
        "Company.Service.ServiceCallEquipmentModel": [],
        "Company.Service.ServiceCallListModel": [],
        "Company.Service.ServiceCallMaterialModel": [{ prop: "Employee", model: "Company.Employee.EmployeeModel" }],
        "Company.Service.ServiceCallMaterialOptionsModel": [],
        "Company.Service.ServiceCallNoteListModel": [],
        "Company.Service.ServiceCallNoteModel": [],
        "Company.Service.ServiceCallStatusModel": ["Date", { prop: "localizationModel", model: "Company.Dispatch.LocalizationModel" }, { prop: "Signature", model: "Company.Document.DocumentModel" }],
        "Company.Service.ServiceCallTimesheetHeaderModel": [],
        "Company.Service.ServiceCallTimesheetModel": ["Date", { prop: "Employee", model: "Company.Employee.EmployeeModel" }],
        "Company.Service.ServiceCallTypeModel": [],
        "Company.Service.ServiceCallVisitHistoryItemModel": ["DispatchDate", "DispatchEndDate", "LastVisitDate"],
        "Company.Service.VisitModel": ["Date"],
        "Company.ServiceCall.ServiceCallDispatchResponseModel": [],
        "Company.ServiceCall.ServiceCallEmergencyTypeModel": [],
        "Company.ServiceCall.ServiceCallNonBillingReasonModel": [],
        "Company.ServiceCall.ServiceCallOccupationCategoryModel": [],
        "Company.ServiceCall.ServiceCallQuotationCustomerProjetContractModel": [],
        "Company.ServiceCall.ServiceCallQuotationDetailsModel": ["Date", "CreationDate", "DateSubmitted", "MaxResponseDate", "FollowupDate", "DateAccepted", "RequiredDate", { prop: "Address", model: "Company.LocationModel" }, "AutomaticDateTimeLastModification"],
        "Company.ServiceCall.ServiceCallQuotationEquipmentModel": [],
        "Company.ServiceCall.ServiceCallQuotationHistoryModel": ["Date"],
        "Company.ServiceCall.ServiceCallQuotationItemModel": [],
        "Company.ServiceCall.ServiceCallQuotationSaveModel": ["Date", "DateSubmitted", "MaxResponseDate", "FollowupDate", "DateAccepted", "RequiredDate", "AutomaticDateTimeLastModification"],
        "Company.ServiceCall.ServiceCallQuotationTypeModel": [],
        "Company.ServiceCall.ServiceCallQuotationUserValidationModel": [],
        "Company.Shift.ShiftModel": [],
        "Company.SMS.SmsMessageModel": ["SentDate"],
        "Company.SMS.SmsResponseModel": [],
        "Company.Template.CatalogFilterConfigModel": [],
        "Company.Template.DispatchTemplateCatalogFilterConfigModel": [],
        "Company.Template.DispatchTemplateModel": [],
        "Company.Timesheet.BonusesHeaderModel": [],
        "Company.Timesheet.TimesheetEntryModel": ["TimeEntryDate", "StartTime", "EndTime"],
        "Company.Timesheet.TimesheetEntrySaveModel": ["TimeEntryDate"],
        "Company.Timesheet.TimesheetGroupingContainerModel": [{ prop: "CurrentEmployee", model: "Company.Employee.EmployeeModelBase" }, "PayPeriodStartDate", "PayPeriodEndDate"],
        "Company.Timesheet.TimesheetModel": ["Date", "EntryDate"],
        "Company.Workorder.FieldServicesWorkOrderAttendanceModel": ["DispatchStartTime", "DispatchEndTime"],
        "Company.Workorder.FieldServicesWorkOrderDateGroupModel": ["Date"],
        "Company.Workorder.FieldServicesWorkOrderDetailsModel": [{ prop: "ReadyToComplete", model: "Company.WorkOrder.FieldServicesWorkOrderReadyToCompleteModel" }, "StartTime", "EndTime", "Date"],
        "Company.Workorder.FieldServicesWorkOrderLookupModel": [],
        "Company.Workorder.FieldServicesWorkOrderModel": ["Date"],
        "Company.Workorder.FieldServicesWorkOrderWriteModel": ["Date"],
        "Company.Workorder.InventoryProjectTransferWorkOrderLookupModel": [],
        "Company.Workorder.ServiceWorkOrderWriteModel": ["Date", "StartDateTime", "EndDateTime", { prop: "location", model: "Company.WorkOrderLocationModel" }],
        "Company.Workorder.WorkOrderBreaksTiresModel": [],
        "Company.Workorder.WorkorderCustomStatusModel": [],
        "Company.Workorder.WorkOrderDetailModel": [{ prop: "Contract", model: "Company.Project.ProjectModel" }, { prop: "ContactDetails", model: "Company.Contact.ContactModel" }, { prop: "ReadyToComplete", model: "Company.Service.DispatchReadyToCompleteModel" }, "WorkorderDate", "AssignedDate", "AssignedEndDate", "TravelStartTime", { prop: "Location", model: "Company.LocationModel" }],
        "Company.Workorder.WorkorderHistoryModel": ["AssignedDate"],
        "Company.Workorder.WorkorderListModel": ["AssignedDate", "AssignedEndDate", "TravelStartTime", { prop: "Location", model: "Company.LocationModel" }],
        "Company.Workorder.WorkOrderMaintenanceModel": [],
        "Company.Workorder.WorkOrderMaintenanceTaskModel": [],
        "Company.Workorder.WorkOrderMaterialModel": [{ prop: "Employee", model: "Company.Employee.EmployeeModel" }],
        "Company.Workorder.WorkOrderMaterialOptionsModel": [],
        "Company.Workorder.WorkorderModel": ["WorkorderDate", "AssignedDate", "AssignedEndDate", "TravelStartTime", { prop: "Location", model: "Company.LocationModel" }],
        "Company.Workorder.WorkOrderNoteListModel": [],
        "Company.Workorder.WorkOrderNoteModel": [],
        "Company.Workorder.WorkOrderPriorityModel": [],
        "Company.Workorder.WorkOrderProgressStatusModel": [],
        "Company.Workorder.WorkOrderStatusModel": ["Date", { prop: "localizationModel", model: "Company.Dispatch.LocalizationModel" }, { prop: "Signature", model: "Company.Document.DocumentModel" }],
        "Company.Workorder.WorkOrderSummaryModel": [{ prop: "Detail", model: "Company.Workorder.WorkOrderDetailModel" }, { prop: "Notes", model: "Company.Workorder.WorkOrderNoteListModel" }, { prop: "Labors", model: "Company.Workorder.WorkOrderTimesheetHeaderModel" }, { prop: "Client", model: "Company.Client.ClientModel" }, { prop: "Status", model: "Company.Workorder.WorkOrderStatusModel" }],
        "Company.Workorder.WorkorderTimesheetBonusModel": [],
        "Company.Workorder.WorkOrderTimesheetHeaderModel": [],
        "Company.Workorder.WorkOrderTimesheetModel": ["Date", { prop: "Employee", model: "Company.Employee.EmployeeModel" }],
        "Company.Workorder.WorkOrderTimesheetResourceModel": [],
        "Company.Workorder.WorkOrderTypeModel": [],
        "Company.Workorder.WorkOrderWorkTaskDetailsModel": ["DateRequired", "DateCompleted"],
        "Company.Workorder.WorkOrderWorkTaskModel": ["DateRequired", "DateCompleted"],
        "Company.WorkOrder.FieldServicesWorkOrderReadyToCompleteModel": [],
        "Procore.Company.ProcoreCompanyModel": [],
        "Procore.Errors_Logger.ProcoreError": [],
        "Procore.Errors_Logger.ProcoreErrorGroup": [],
        "Procore.Job_System.ProcoreJobStatus": ["DelayedUntilDate", "CreationDate", "LastUpdatedDate"],
        "Procore.Loaders.BaseLoaderEntity": ["LastSynchronizationDateTime", "MaestroDateTime", "ProcoreDateTime", "LastUpdateDateTime"],
        "Procore.Loaders.BudgetLoaderEntity": ["LastSynchronizationDateTime", "MaestroDateTime", "ProcoreDateTime", "LastUpdateDateTime"],
        "Procore.Loaders.DirectCostTransactionLoaderEntity": ["AccountingDate", "DueDate", "StartDate", "EndDate", "InvoiceDate", "LastSynchronizationDateTime", "MaestroDateTime", "ProcoreDateTime", "LastUpdateDateTime"],
        "Procore.Loaders.EmployeeLoaderEntity": ["LastSynchronizationDateTime", "MaestroDateTime", "ProcoreDateTime", "LastUpdateDateTime"],
        "Procore.Loaders.PccoChangeOrderLoaderEntity": ["AccountingDate", "LastSynchronizationDateTime", "MaestroDateTime", "ProcoreDateTime", "LastUpdateDateTime"],
        "Procore.Loaders.PccoClientLoaderEntity": ["LastSynchronizationDateTime", "MaestroDateTime", "ProcoreDateTime", "LastUpdateDateTime"],
        "Procore.Loaders.PrimeContractLoaderEntity": ["StartDate", "LastSynchronizationDateTime", "MaestroDateTime", "ProcoreDateTime", "LastUpdateDateTime"],
        "Procore.Loaders.ProcoreBudgetsForAProject": [],
        "Procore.Loaders.ProcoreTransactionGroup": [],
        "Procore.Loaders.ProcoreTransactionsForAProject": [],
        "Procore.Loaders.ProjectLoaderEntity": ["LastSynchronizationDateTime", "MaestroDateTime", "ProcoreDateTime", "LastUpdateDateTime"],
        "Procore.Loaders.SupplierLoaderEntity": ["LastSynchronizationDateTime", "MaestroDateTime", "ProcoreDateTime", "LastUpdateDateTime"],
        "Procore.Loaders.TransactionLoaderEntity": ["AccountingDate", "LastSynchronizationDateTime", "MaestroDateTime", "ProcoreDateTime", "LastUpdateDateTime"],
        "Procore.Preview.ProcoreDifferenceEntityChange": [],
        "Procore.Preview.ProcoreDifferenceModel": ["MaestroTransactionDate", "MaestroTimestamp", "ProcoreTimestamp"],
        "Procore.Preview.ProcoreDifferenceModelGroupedByProject": ["MaestroTransactionDate", "MaestroTimestamp", "ProcoreTimestamp"],
        "Procore.Preview.ProcoreDifferenceModelGroupedByProjectAndGrouping": ["MaestroTransactionDate", "MaestroTimestamp", "ProcoreTimestamp"],
        "Procore.Preview.ProcoreDifferenceOrderInvoiceModel": ["MaestroTransactionDate", "MaestroTimestamp", "ProcoreTimestamp"],
        "Procore.Preview.ProcoreDifferenceOrdersModel": ["MaestroTransactionDate", "MaestroTimestamp", "ProcoreTimestamp"],
        "Procore.Preview.ProcoreDifferencePrimeContractChangeOrdersModel": ["ReviewedDate", "MaestroTransactionDate", "MaestroTimestamp", "ProcoreTimestamp"],
        "Procore.Preview.ProcorePreviewResults": [],
        "Procore.Synchronization.ProcoreAbstractSynchronizationResultDifferenceModel": [],
        "Procore.Synchronization.ProcoreChildSynchronizationResultDifferenceModel": [],
        "Procore.Synchronization.ProcoreResultLists": [{ prop: "Projects", model: "Procore.Synchronization.ProcoreResultsModel" }, { prop: "Suppliers", model: "Procore.Synchronization.ProcoreResultsModel" }, { prop: "PurchaseOrders", model: "Procore.Synchronization.ProcoreResultsModel" }, { prop: "ContractsAndChangeOrders", model: "Procore.Synchronization.ProcoreResultsModel" }, { prop: "PrimeContractChangeOrders", model: "Procore.Synchronization.ProcoreResultsModel" }, { prop: "Budgets", model: "Procore.Synchronization.ProcoreResultsModel" }, { prop: "OrderInvoices", model: "Procore.Synchronization.ProcoreResultsModel" }, { prop: "DirectCosts", model: "Procore.Synchronization.ProcoreResultsModel" }],
        "Procore.Synchronization.ProcoreResultsModel": [],
        "Procore.Synchronization.ProcoreSynchronizationResponse": [],
        "Procore.Synchronization.ProcoreSynchronizationResult": ["TimeStart", "TimeEnd", { prop: "Results", model: "Procore.Synchronization.ProcoreResultLists" }],
        "Procore.Synchronization.ProcoreSynchronizationResultDifferenceModel": [],
        "Procore.Synchronization.ProcoreSynchronizationStatusInformation": [],
        "Procore.Synchronization.ProcoreValueDifference": [],
        "Procore.Synchronizers.Activities.ActivitiesSynchronizerResult": [],
        "Procore.Synchronizers.BaseClasses.BaseSynchronizationResult": [],
        "Procore.Synchronizers.BudgetModification.BudgetModificationSynchronizationResult": [],
        "Procore.Synchronizers.Budgets.BudgetSynchronizationResult": [],
        "Procore.Synchronizers.Employees.EmployeesSynchronizationResult": [],
        "Procore.Synchronizers.PccoClient.PccoClientSynchronizationResult": [],
        "Procore.Synchronizers.PrimeContracts.PrimeContractSynchronizationResult": [],
        "Procore.Synchronizers.Projects.ProjectSynchronizationResult": [],
        "Procore.Synchronizers.Suppliers.SupplierSynchronizationResult": [],
        "Procore.Synchronizers.Transactions.DirectCostsDeleteSynchronizationResult": [],
        "Procore.Synchronizers.Transactions.OrderInvoicesDeleteSynchronizationResult": [],
        "Procore.Synchronizers.Transactions.TransactionResult": [],
        "Procore.Synchronizers.Transactions.TransactionsSynchronizationResult": [],
};
