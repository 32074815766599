import { computedFrom, transient } from "aurelia-framework";
import { bindable } from "aurelia-typed-observable-plugin";

import { default as dateHelper } from "helpers/dateHelper";
import Parse from "helpers/parse";

@transient()
export class DailyEntryButton {
    public dateHelper: any = dateHelper;
    @bindable.booleanAttr public isProjectResponsible: boolean = false;
    @bindable.string public date: string = "";
    @bindable.string public status: string = "";
    @bindable.booleanAttr public isCanceled: boolean = false;
    @bindable.string public urlToGo: string = "";
    @bindable.booleanAttr public noIcons: boolean = false;
    @bindable.string public cancelReason: string = "";

    @computedFrom("status")
    public get isComplete(): boolean {
        return Parse.Boolean(this.status && this.status !== "2" && this.status !== "X");
    }

    @computedFrom("status")
    public get isReturn(): boolean {
        return Parse.Boolean(this.status && this.status === "X");
    }

    @computedFrom("status")
    public get isInProgress(): boolean {
        return Parse.Boolean(this.status && this.status === "2");
    }
}
